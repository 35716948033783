import { Component, Input} from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ActivatedRoute, Router} from '@angular/router';
import { ApplicationApi } from '../../@core/backend/common/api/application.api';
import {  NbToastrService } from '@nebular/theme';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
    selector: 'nb-dialog',
   
    template: `<nb-card [style.height.px]="600" status="success" accent="success" size="xsmall" nbSpinnerStatus="success"
    nbSpinnerSize="xlarge">
    <nb-card-header>
        <div class="row">
            <div class="col-md-3">
            </div>
            <div class="col-md-6">
                <h3 style="color:#ffffff"> PAYMENT</h3>
            </div>
            <div class="col-md-3">
            </div>
        </div>
    </nb-card-header>

    <nb-card-body class="example-items-col">
   

    <div class="row" style="margin-bottom:10px">
 
</div>
        <div class="row" style="margin-bottom:10px">
            <div class="col-md-3" style="margin-bottom:5px">Name</div>
            <div class="col-md-9">
                <input class="form-control" status="primary" type="text" placeholder="Name"
                    ngModel="{{userdata[0].name}}" [readonly]="true">
            </div>
        </div>
        <!--  <div class="row" style="margin-bottom:10px">
            <div class="col-md-3" style="margin-bottom:5px">Address</div>
            <div class="col-md-9">
                <input type="text" class="form-control" nbInput fullWidth placeholder="Address"
                    ngModel="{{userdata[0].address}}" [readonly]="true">
            </div>
        </div> 
        <div class="row" style="margin-bottom:10px">
            <div class="col-md-3" style="margin-bottom:5px">City</div>
            <div class="col-md-9">
                <input type="text" class="form-control" nbInput fullWidth placeholder="City"
                    ngModel="{{userdata[0].city}}" [readonly]="true">
            </div>
        </div>
        <div class="row" style="margin-bottom:10px">
            <div class="col-md-3" style="margin-bottom:5px">State</div>
            <div class="col-md-9">
                <input type="text" class="form-control" nbInput fullWidth placeholder="State"
                    ngModel="{{userdata[0].state}}" [readonly]="true">
            </div>
        </div>
        <div class="row" style="margin-bottom:10px">
            <div class="col-md-3" style="margin-bottom:5px">Zipcode</div>
            <div class="col-md-9">
                <input type="text" class="form-control" nbInput fullWidth placeholder="Zipcode"
                    ngModel="{{userdata[0].postal_code}}" [readonly]="true">
            </div>
        </div>
         <div class="row" style="margin-bottom:10px">
            <div class="col-md-3" style="margin-bottom:5px">Country</div>
            <div class="col-md-9">
              <input type="text" class="form-control"  nbInput fullWidth placeholder="Country"  [readonly]="true">
            </div>
          </div> -->
        <div class="row" style="margin-bottom:10px">
            <div class="col-md-3" style="margin-bottom:5px">Telephone</div>
            <div class="col-md-9">
                <input type="text" class="form-control" nbInput fullWidth placeholder="Telephone"
                    ngModel="{{userdata[0].mobile}}" [readonly]="true">
            </div>
        </div>
        <div class="row" style="margin-bottom:10px">
            <div class="col-md-3" style="margin-bottom:5px">Email</div>
            <div class="col-md-9">
                <input type="text" class="form-control" nbInput fullWidth placeholder="Email"
                    ngModel="{{userdata[0].email}}" >
            </div>
        </div>
        
        <div class="row" style="margin-bottom:10px">
            <div class="col-md-3" style="margin-bottom:5px">Amount</div>
            <div class="col-md-9">
                <input type="text" class="form-control" nbInput fullWidth placeholder="Amount" ngModel="{{value}}"
                    [readonly]="true">
            </div>
        </div>

        <div class="row" style="margin-bottom:7px;">
            <div class="col-md-3" style="margin-bottom:5px">Delivery Type</div>
            <div class="col-md-9">
                <select style="  " name="passMonthCtrl" id="passMonthCtrl" class="form-control"
                    placeholder="result/class" disabled="disabled" ngModel="Normal">
                    <option selected="true" value="Normal">Normal</option>

                </select>

            </div>

        </div>


        <div class="row" style="margin-bottom:10px">
        <div class="col-md-3" style="margin-bottom:5px">PRN No</div>
        <div class="col-md-9">
            <input type="text" class="form-control" nbInput fullWidth placeholder="Zipcode"
                ngModel="{{EducationalFormData.enrollment_number}}" [readonly]="true">
        </div>
    </div>
    <div class="row" style="margin-bottom:10px">
    <div class="col-md-3" style="margin-bottom:5px">Course</div>
    <div class="col-md-9">
        <input type="text" class="form-control" nbInput fullWidth placeholder="Zipcode"
            ngModel="{{EducationalFormData.applying_for}}" [readonly]="true">
    </div>

                <!-- Alternate address for courired-->
        <form class="form" role="form" [formGroup]="secondForm" class="step-container">
            <div class="row" *ngIf="addCheck == true">
                <div class="col-xl-6" style="padding-left: 30px;padding-top: 20px;">
                    Address: <span style="color:red;">*</span>
                </div>
                <div class="col-xl-6">
                    <mat-form-field style="width:200px !important margin-left:50px;">
                        <input class="form-control" matInput formControlName="new_addressCtrl"
                            [ngClass]="{'form-control-danger': secondForm.controls.new_addressCtrl.invalid && (secondForm.controls.new_addressCtrl.dirty || secondForm.controls.new_addressCtrl.touched)}">
                    </mat-form-field>
                    <br />
                    <p style="color:red;width:inherit;text-align: start;"
                        *ngIf="secondForm.controls['new_addressCtrl'].invalid && (secondForm.controls['new_addressCtrl'].dirty || secondForm.controls['new_addressCtrl'].touched)">
                        Please Enter Address!</p>
                </div>
            </div>
            <div class="row" *ngIf="addCheck == true">
                <div class="col-xl-6" style="padding-left: 30px;padding-right: 25px;padding-top: 20px;">
                    Pincode: <span style="color:red;">*</span>
                </div>
                <div class="col-xl-6">
                    <mat-form-field style="width:200px; !important">
                        <input class="form-control" pattern="[0-9]+" matInput (keyup)="pincodeChanges($event,'new')"
                            formControlName="new_pincodeCtrl"
                            [ngClass]="{'form-control-danger': secondForm.controls.new_pincodeCtrl.invalid && (secondForm.controls.new_pincodeCtrl.dirty || secondForm.controls.new_pincodeCtrl.touched)}">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div *ngIf="addCheck == true" class="col-xl-3"
                    style="padding-left: 30px;padding-right: 25px;padding-top: 20px;">
                    <label for="inputarea">Area-Location :<span style="color:red;">*</span> </label>
                </div>
                <input style="width:200px" *ngIf="editAreaLocation == true" type="text"
                    style="font-size: 20px; border-bottom-width: thick;" class="form-control" id="inputarea"
                    placeholder="Area - Location" formControlName="new_cityEditCtrl">
                <nb-select style="width:200px" style="margin-left:180px; width:200px;" *ngIf="editAreaLocation == false"
                    placeholder="Please select a location" class="uk-width-1-1" [ngModel]="city"
                    formControlName="new_cityCtrl" (selectedChange)="onChangeAreaLocation($event)">
                    <nb-option *ngFor="let add of addresses" [value]="add" [(selected)]="area">
                        {{add.office_name}}
                    </nb-option>
                </nb-select>
            </div>

            <div class="row" *ngIf="addCheck == true">
                <div class="col-xl-6" style="padding-left: 30px;padding-right: 25px;padding-top: 20px;">
                    District: <span style="color:red;">*</span>
                </div>
                <div class="col-xl-6">
                    <mat-form-field style="width:200px !important">
                        <input class="form-control" matInput formControlName="new_districtCtrl"
                            [ngClass]="{'form-control-danger': secondForm.controls.new_districtCtrl.invalid && (secondForm.controls.new_districtCtrl.dirty || secondForm.controls.new_districtCtrl.touched)}">
                    </mat-form-field>
                </div>
            </div>

            <div class="row" *ngIf="addCheck == true">
                <div class="col-xl-6" style="padding-left: 30px;padding-right: 25px;padding-top: 20px;">
                    State: <span style="color:red;">*</span>
                </div>
                <div class="col-xl-6">
                    <mat-form-field style="width:200px !important">
                        <input class="form-control" matInput formControlName="new_stateCtrl"
                            [ngClass]="{'form-control-danger': secondForm.controls.new_stateCtrl.invalid && (secondForm.controls.new_stateCtrl.dirty || secondForm.controls.new_stateCtrl.touched)}">
                    </mat-form-field>
                </div>
            </div>
            <div class="row" style="margin-top: 20px;">
                <button nbButton [disabled]="secondForm.valid===false" nbButton class="btn btn-primary"
                    *ngIf="addCheck == true" (click)="save_add()">save</button>
            </div>
        </form>
        <!--Alternate address for couriered-->


                <nb-card-footer>
                <h6 style="color:  red">Note: Once the payment is done Fees Are Non Refundable. (एकदा पेमेंट केले की फी परत न करण्यायोग्य आहे.) 
                The application will be processed only after successful completion of the payment. (पेमेंट यशस्वीरित्या पूर्ण झाल्यानंतरच अर्जावर प्रक्रिया केली जाईल.)
                .</h6>
                <div style="display: flex; justify-content: space-around;">
                <button style="background-color: red; margin-right: 10px;" nbButton class="btn btn-primary" (click)="dismiss()">Close</button>
                <button nbButton class="btn btn-primary" *ngIf="payshow" (click)="payment()">Proceed For Payment</button>
                </div>
            

                </nb-card-footer>

        </div>
      
    </nb-card-body>
    
</nb-card>

<form id="nonseamless" method="post" name="redirect" action="{{secureUrl}}"> <input type="hidden" id="encRequest"
        name="encRequest" value="{{encRequest}}"><input type="hidden" name="access_code" id="access_code"
        value="{{accessCode}}">
</form>`,
    })
export class PaymentComponent {
     @Input() userdata: any;
     @Input() amount : any;
     @Input() id : any;
     @Input() EducationalFormData : any;

    // @Input() collegeConfirmation;
    notes;
    addNote: boolean = false;
  app_id: any;
  accessCode: any;
  secureUrl: any;
  encRequest: any;
  value: any;
  type: any;
  secondForm: FormGroup;
  thirdForm: FormGroup;

  paymentbutton: boolean;
  address_id: string;
  add_validation: boolean;
  other: boolean;
  addCheck: boolean;
  areaLocation: any;
  other_add: any;
  addresses: any;
  editAreaLocation: boolean;
  form: FormGroup;

  edudata: any;
  mesage: string;
  addreshow: boolean=false;
  payshow: boolean=false;
  addAddress: boolean=false;
  educatiopreview: any;
  valuedata: number=0;
    constructor(protected ref: NbDialogRef<PaymentComponent>,
      private router : Router,
      private authService: NbAuthService,
      protected api : ApplicationApi,
      private toastrService: NbToastrService,
      private route: ActivatedRoute,
      private fb: FormBuilder,
      ) {

    }
    ngOnInit(): void {
      this.getother()
   
       this.form = this.fb.group({
        credentials: this.fb.array([]),
      });
    this.secondForm = this.fb.group({
      new_addressCtrl : [''],
      new_cityEditCtrl : [''],
      new_cityCtrl : [''],
      new_districtCtrl : [''],
      new_stateCtrl : [''],
      new_pincodeCtrl : [''],
      website:['pickup'],
      
    })
    this.thirdForm = this.fb.group({
      addressCtrl :[''],
      website:['pickup']
    })
    this.onChange('Normal')
    }

    getother(){
      this.api.getOtherAddress(this.id,this.address_id).subscribe(data=>{
        if(data['status']==200){
          this.other_add = data['data'];
          
          
        }
       })
    }
    onChange(event){
     (<FormArray> this.form.controls['credentials']).clear();
      this.type = event
      var website = this.thirdForm.controls.website.value
      if(website=='old'){
        this.addreshow=false
        this.payshow=true;
        this.mesage="Once the Documents are ready, they will be sent to the email address provided by the purpose recipient. (कागदपत्रे तयार झाल्यानंतर, ते उद्देश प्राप्तकर्त्याद्वारे प्रदान केलेल्या ईमेल पत्त्यावर पाठवले जातील.)"
      }else if(website=='new'){
        this.addAddress=  true
        this.payshow=true;
        this.addreshow=true
        this.mesage="Kindly input the delivery address for the Documents. (The address will not be changed or modified.) कृपया दस्तऐवजांसाठी वितरण पत्ता प्रविष्ट करा. (पत्ता बदलला जाणार नाही किंवा बदलला जाणार नाही.)"
      }else if(website=='pickup'){
        this.payshow=true;
        this.addreshow=true
        this.addAddress=  true
        this.mesage="Once the Documents are ready, we will notify you at your registered address, and you can collect them from the University. (कागदपत्रे तयार झाल्यावर, आम्ही तुम्हाला तुमच्या नोंदणीकृत पत्त्यावर सूचित करू आणि तुम्ही ते विद्यापीठाकडून गोळा करू शकता.)"
      }
      this.api.getamount(this.type,this.thirdForm.controls.website.value).subscribe(data => {
          if (data['status'] == 200) {
              this.value=data['data']
              this.edudata=data['edudata']
              
              
              this.edudata.forEach((element)=>{
                //this.getalldata();            
                (<FormArray> this.form.controls['credentials']).push(this.buildItemEducation(element.enrollment_number,element.address,element.applying_for));
              })            
              this.paymentbutton=true; 
              if(this.address_id != '' && this.address_id != null){
                this.add_validation = true;// 
              }
              // 

          } else {
            this.toastrService.danger('Error : Saving Details', 'Status');
          }
      });

    }

    onSelectaddress(event){
      // 
      // if(event){
        if(event == 0){
          this.other = true;
          this.add_validation = false;
        }else{
          this.address_id = event;
          
          this.other = false;
          this.addCheck = false; 
          this.add_validation = true;
        }
      // }
    }
    onChanges(){
      this.payshow=true
    //   this.api.getedu().subscribe(data => {
    //     if(data['status']==200){
    //        this.educatiopreview=data['edudata']
    //     }else{
    //       
    //     }
    // });
      // 
      //  var year= ((<FormArray>this.EduForm.controls['educationarray']).at(event).value.passingyearCtrl);
     }

    onChangeAreaLocation(location){ 
      this.areaLocation = location.office_name;
      var district = location.district_name;
      var state = location.state_name;
      
      this.secondForm.patchValue({
        new_districtCtrl : district
      })
      this.secondForm.patchValue({
        new_stateCtrl : state
      })
      this.secondForm.patchValue({
        new_cityEditCtrl : ''
      })
    }
  
    altaddCheck(event) {
      
      
      if(event.target.checked == true){
        this.addCheck = true;
        // this.alt_address = true;
        this.secondForm.controls.new_addressCtrl.setValidators([Validators.required]);
        this.secondForm.controls.new_addressCtrl.updateValueAndValidity();
        this.secondForm.controls.new_pincodeCtrl.setValidators([Validators.required, Validators.maxLength(10), Validators.minLength(5)]);
        this.secondForm.controls.new_pincodeCtrl.updateValueAndValidity();
        this.secondForm.controls.new_districtCtrl.setValidators([Validators.required]);
        this.secondForm.controls.new_districtCtrl.updateValueAndValidity();
        this.secondForm.controls.new_stateCtrl.setValidators([Validators.required]);
        this.secondForm.controls.new_stateCtrl.updateValueAndValidity();
        this.secondForm.get('new_addressCtrl').enable();
        this.secondForm.get('new_pincodeCtrl').enable();
        this.secondForm.get('new_districtCtrl').enable();
        this.secondForm.get('new_stateCtrl').enable();
      }else{
        this.addCheck = false;
        // this.alt_address = false;

        this.secondForm.controls.new_addressCtrl.setValidators([]);
        this.secondForm.controls.new_addressCtrl.updateValueAndValidity();
    
        this.secondForm.controls.new_pincodeCtrl.setValidators([]);
        this.secondForm.controls.new_pincodeCtrl.updateValueAndValidity();
        this.secondForm.controls.new_stateCtrl.setValidators([]);
        this.secondForm.controls.new_stateCtrl.updateValueAndValidity();
        this.secondForm.controls.new_districtCtrl.setValidators([]);
        this.secondForm.controls.new_districtCtrl.updateValueAndValidity();

        this.secondForm.get('new_addressCtrl').disable();
        this.secondForm.get('new_pincodeCtrl').disable();
        this.secondForm.get('new_districtCtrl').disable();
        this.secondForm.get('new_stateCtrl').disable();
       }
    }

    // handleClear(){
    //   this.secondForm.controls.new_addressCtrl,
    // }
    buildItemEducation(val1,val2,val3){
    
      return new FormGroup({
        enrolloment: new FormControl(val1, Validators.required),
        course: new FormControl(val3, Validators.required),

        address: new FormControl(val2, Validators.required),

       
      })
    }
    save_add(){// 
   // 
      var newAddress={
        new_address:this.secondForm.controls.new_addressCtrl.value,
        new_pincode:this.secondForm.controls.new_pincodeCtrl.value,
        new_city:this.secondForm.controls.new_cityEditCtrl.value?this.secondForm.controls.new_cityEditCtrl.value:this.areaLocation,
        new_district:this.secondForm.controls.new_districtCtrl.value,
        new_state:this.secondForm.controls.new_stateCtrl.value,
        user_id : this.id
      };  //
      
    var addAlterAdd = this.api.addAlternateAddress(newAddress,'',this.address_id);
    addAlterAdd.subscribe(data =>{
      if(data['status'] == 200){ // 
        if(data['id']!=undefined || data['id']!=''){
          // this.selected = true;
          this.addCheck=false
          this.add_validation = true;
          this.address_id = data['id'];
          this.getother()
                  this.secondForm.get('new_addressCtrl').enable();

          // this.amountPayment = data['data'];
          if(this.value != null){ //
            this.paymentbutton = true;
          }      
          // this.quick_message = data['quick_message'];
        }
      }else{
        // this.errormessage = true;
      }
    },
    err=>{
      // 
  
    // if(err){
    //   this.errormessage = true;
    //   this.selected = false;
    // }
    })
    }

    dismiss() {
      this.ref.close();
      }
      async payment(){

          var edu = this.userdata[0].address + this.userdata[0].city +this.userdata[0].district+this.userdata[0].state+this.userdata[0].postal_code;
          
          let addobject={'address':edu,'addtype':'new'}
          var addresss=[]
          addresss.push(addobject)


          var payment = await this.api.paymentrequests(this.value,'',this.userdata,this.type,this.address_id,this.thirdForm.controls.addressCtrl.value,this.thirdForm.controls.website.value,this.EducationalFormData);
            payment.subscribe(
            data => {
              this.accessCode = data['data']['accessCode'];
              this.secureUrl = data['data']['secureUrl'];
              this.encRequest = data['data']['encRequest'];
              setTimeout(function(){
              this.loading = false;
                var myForm = <HTMLFormElement>document.getElementById('nonseamless');
                myForm.submit();
              }, 1000);
            },
              error => {
              }
          );
      }

    pincodeChanges(event){
      this.secondForm.get('new_cityCtrl').enable();
      this.secondForm.get('new_districtCtrl').enable();
      this.secondForm.get('new_stateCtrl').enable();
      this.api.checkPinValues(this.secondForm.controls.new_pincodeCtrl.value)
      .subscribe(
        (data: any) => {
          if(data['status'] == 200){
            this.addresses = data['data'];
            if(this.addresses.length==0){
              this.editAreaLocation = true;
              this.secondForm.get('new_cityEditCtrl').setValidators([]);
            }else{
              this.editAreaLocation = false;
              this.secondForm.get('new_cityCtrl').setValidators([]);
              this.secondForm.get('new_districtCtrl').disable();
              this.secondForm.get('new_stateCtrl').disable();
            }	
          }else{
            this.secondForm.patchValue({
              new_cityCtrl : ''
            })
            this.secondForm.patchValue({
              new_districtCtrl : ''
            })
            this.secondForm.patchValue({
              new_stateCtrl : ''
            })
          }
      })
      
    }
}